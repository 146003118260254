var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-tabs',{attrs:{"dark":"","fixed-tabs":"","background-color":"teal darken-3"},model:{value:(_vm.tabComponente),callback:function ($$v) {_vm.tabComponente=$$v},expression:"tabComponente"}},_vm._l((_vm.tabs),function(item){return _c('v-tab',{key:item.tab},[_vm._v(" "+_vm._s(item.tab)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.tabComponente),callback:function ($$v) {_vm.tabComponente=$$v},expression:"tabComponente"}},[_c('v-form',{ref:_vm.formNome},_vm._l((_vm.form.tabsConteudo),function(linha,name,index){return _c('v-tab-item',{key:name,attrs:{"eager":""}},_vm._l((linha),function(dados,indexLinha){return _c('v-row',{key:indexLinha},_vm._l((dados),function(conteudo,idx){return _c('v-col',{key:idx,attrs:{"cols":_vm.tabs[index].form.colunas[idx].cols,"sm":_vm.tabs[index].form.colunas[idx].sm}},[(
                                _vm.tabs[index].form.campoConfiguracao[idx]
                                    .tipo == 'autocomplete'
                            )?_c('v-autocomplete',{attrs:{"items":_vm.form.complemento[
                                    _vm.tabs[index].form.campoConfiguracao[idx]
                                        .caminho
                                ],"label":_vm.tabs[index].form.campoConfiguracao[idx]
                                    .label,"rules":_vm.tabs[index].form.regra[idx],"disabled":_vm.tabs[index].form.desativado[idx],"required":_vm.tabs[index].form.obrigatorio[idx],"item-value":_vm.tabs[index].form.campoConfiguracao[idx]
                                    .campo.valor,"item-text":_vm.tabs[index].form.campoConfiguracao[idx]
                                    .campo.texto},model:{value:(linha[indexLinha][idx]),callback:function ($$v) {_vm.$set(linha[indexLinha], idx, $$v)},expression:"linha[indexLinha][idx]"}}):_vm._e(),(
                                _vm.tabs[index].form.campoConfiguracao[idx]
                                    .tipo == 'select'
                            )?_c('v-select',{attrs:{"items":_vm.form.complemento[
                                    _vm.tabs[index].form.campoConfiguracao[idx]
                                        .caminho
                                ],"label":_vm.tabs[index].form.campoConfiguracao[idx]
                                    .label,"rules":_vm.tabs[index].form.regra[idx],"disabled":_vm.tabs[index].form.desativado[idx],"required":_vm.tabs[index].form.obrigatorio[idx],"item-value":_vm.tabs[index].form.campoConfiguracao[idx]
                                    .campo.valor,"item-text":_vm.tabs[index].form.campoConfiguracao[idx]
                                    .campo.texto},model:{value:(linha[indexLinha][idx]),callback:function ($$v) {_vm.$set(linha[indexLinha], idx, $$v)},expression:"linha[indexLinha][idx]"}}):_vm._e(),(
                                _vm.tabs[index].form.campoConfiguracao[idx]
                                    .tipo == 'input'
                            )?_c('v-text-field',{attrs:{"rules":_vm.tabs[index].form.regra[idx],"counter":_vm.tabs[index].form.tamanho[idx].max,"label":_vm.tabs[index].form.placeholder[idx],"disabled":_vm.tabs[index].form.desativado[idx],"required":_vm.tabs[index].form.obrigatorio[idx]},model:{value:(linha[indexLinha][idx]),callback:function ($$v) {_vm.$set(linha[indexLinha], idx, $$v)},expression:"linha[indexLinha][idx]"}}):_vm._e()],1)}),1)}),1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }