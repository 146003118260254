<template>
    <v-container fluid>
        <v-tabs
            v-model="tabComponente"
            dark
            fixed-tabs
            background-color="teal darken-3"
        >
            <v-tab v-for="item in tabs" :key="item.tab">
                {{ item.tab }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabComponente">
            <v-form :ref="formNome">
                <v-tab-item
                    eager
                    v-for="(linha, name, index) in form.tabsConteudo"
                    :key="name"
                >
                    <v-row
                        v-for="(dados, indexLinha) in linha"
                        :key="indexLinha"
                    >
                        <v-col
                            v-for="(conteudo, idx) in dados"
                            :key="idx"
                            :cols="tabs[index].form.colunas[idx].cols"
                            :sm="tabs[index].form.colunas[idx].sm"
                        >
                            <v-autocomplete
                                v-model="linha[indexLinha][idx]"
                                v-if="
                                    tabs[index].form.campoConfiguracao[idx]
                                        .tipo == 'autocomplete'
                                "
                                :items="
                                    form.complemento[
                                        tabs[index].form.campoConfiguracao[idx]
                                            .caminho
                                    ]
                                "
                                :label="
                                    tabs[index].form.campoConfiguracao[idx]
                                        .label
                                "
                                :rules="tabs[index].form.regra[idx]"
                                :disabled="tabs[index].form.desativado[idx]"
                                :required="tabs[index].form.obrigatorio[idx]"
                                :item-value="
                                    tabs[index].form.campoConfiguracao[idx]
                                        .campo.valor
                                "
                                :item-text="
                                    tabs[index].form.campoConfiguracao[idx]
                                        .campo.texto
                                "
                            ></v-autocomplete>

                            <v-select
                                v-model="linha[indexLinha][idx]"
                                v-if="
                                    tabs[index].form.campoConfiguracao[idx]
                                        .tipo == 'select'
                                "
                                :items="
                                    form.complemento[
                                        tabs[index].form.campoConfiguracao[idx]
                                            .caminho
                                    ]
                                "
                                :label="
                                    tabs[index].form.campoConfiguracao[idx]
                                        .label
                                "
                                :rules="tabs[index].form.regra[idx]"
                                :disabled="tabs[index].form.desativado[idx]"
                                :required="tabs[index].form.obrigatorio[idx]"
                                :item-value="
                                    tabs[index].form.campoConfiguracao[idx]
                                        .campo.valor
                                "
                                :item-text="
                                    tabs[index].form.campoConfiguracao[idx]
                                        .campo.texto
                                "
                            ></v-select>

                            <v-text-field
                                v-if="
                                    tabs[index].form.campoConfiguracao[idx]
                                        .tipo == 'input'
                                "
                                v-model="linha[indexLinha][idx]"
                                :rules="tabs[index].form.regra[idx]"
                                :counter="tabs[index].form.tamanho[idx].max"
                                :label="tabs[index].form.placeholder[idx]"
                                :disabled="tabs[index].form.desativado[idx]"
                                :required="tabs[index].form.obrigatorio[idx]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-form>
        </v-tabs-items>
    </v-container>
</template>

<script>
import { API_URL } from '../../config/configs';
import { Alerta, MetodoNovoOuAtualizaApi } from '@/helpers/Funcoes.js';
import ApiService from '@/services/ApiService.js';
import DatatableHelper from '@/helpers/Datatables.js';

export default {
    components: {},
    props: [
        'formNome',
        'moduloNome',
        'tabRefs',
        'tabs',
        'chavePrimaria',
        'formComplemento',
        'urlNovoRegistro',
        'urlConsultaRegistro',
    ],
    async created() {
        let self = this;

        this.tabComponente = this.tabRefs;

        this.tabs.forEach(function (dados) {
            let campo = dados.form.campo;
            let tabNome = dados.tab;

            self.form.tabsConteudo = {
                ...self.form.tabsConteudo,
                [tabNome]: campo,
            };
        });
    },
    watch: {},
    computed: {},
    mounted() {},
    methods: {
        async novoForm() {
            try {
                if (this.urlNovoRegistro == '') {
                    this.$store.commit('FORM_ACAO', 'form-novo');
                    this.$store.dispatch('painelAbre');

                    return false;
                }

                const regexp = /\${([^{]+)}/g;

                let urlConsulta = `${
                    API_URL.sim[this.moduloNome][this.urlNovoRegistro]
                }`.replace(regexp, function (ignore, key) {
                    return eval(key);
                });

                let urlConfig = {
                    method: 'get',
                    url: `${urlConsulta}`,
                    data: {},
                };

                let dados = await ApiService(urlConfig)
                    .then((resposta) => {
                        this.$store.commit('FORM_ACAO', 'form-novo');

                        this.form.complemento = resposta.data.conteudo
                            .complemento
                            ? resposta.data.conteudo.complemento
                            : {};

                        this.$store.dispatch('painelAbreFecha');
                    })
                    .catch((erro) => {
                        throw new Error(erro.message);
                    });
            } catch (e) {
                this.$emit('mensagemAlerta', e.message, 'error');
            }
        },
        async consultaRegistro(id_registro) {
            try {
                const regexp = /\${([^{]+)}/g;

                let urlConsulta = `${
                    API_URL.sim[this.moduloNome][this.urlConsultaRegistro]
                }`.replace(regexp, function (ignore, key) {
                    return eval(key);
                });

                let urlConfig = {
                    method: 'get',
                    url: `${urlConsulta}`,
                    data: {},
                };

                let dados = await ApiService(urlConfig)
                    .then((resposta) => {
                        this.$store.commit('FORM_ACAO', 'form-atualiza');

                        Object.keys(resposta.data.conteudo.dados).map(
                            (chave, idx) => {
                                Object.keys(
                                    resposta.data.conteudo.dados[chave][idx]
                                )
                                    .filter(
                                        (key) =>
                                            key in
                                            this.form.tabsConteudo[chave][idx]
                                    )
                                    .forEach((key) => {
                                        this.form.tabsConteudo[chave][idx][
                                            key
                                        ] =
                                            resposta.data.conteudo.dados[chave][
                                                idx
                                            ][key];
                                    });
                            }
                        );

                        this.form.complemento = resposta.data.conteudo
                            .complemento
                            ? resposta.data.conteudo.complemento
                            : {};

                        this.$store.dispatch('painelAbreFecha');
                    })
                    .catch((erro) => {
                        throw new Error(erro.message);
                    });
            } catch (e) {
                Alerta(e.message, 'error');
            }
        },
        fechaForm() {
            try {
                this.$store.commit('FORM_ACAO', 'form-grid');
                this.limpaFormulario();
                this.$emit('datatableRecarrega');
                this.$store.dispatch('painelFecha');
                window.scrollTo(0, 0);
            } catch (e) {
                this.$emit('mensagemAlerta', e.message, 'error');
            }
        },
        async salvaForm() {
            try {
                if (!this.$refs[this.formNome].validate()) {
                    this.$emit(
                        'mensagemAlerta',
                        'Preencha corretamente todos campos',
                        'error'
                    );

                    return false;
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Salvar', 'Salvar registro ?', {
                        cor: 'green',
                    })
                    .then(async (confirma) => {
                        if (!confirma) {
                            return false;
                        }

                        let conteudoFormulario = {};

                        [this.form.tabsConteudo].forEach(function (tab) {
                            conteudoFormulario = {
                                ...conteudoFormulario.dados,
                                ...tab,
                            };
                        });

                        if (conteudoFormulario.dados[0]) {
                            conteudoFormulario.dados =
                                conteudoFormulario.dados[0];
                        }

                        let urlForm = conteudoFormulario.dados[
                            this.chavePrimaria
                        ]
                            ? `${API_URL.sim[this.moduloNome].salva}/${
                                  conteudoFormulario.dados[this.chavePrimaria]
                              }`
                            : `${API_URL.sim[this.moduloNome].salva}`;

                        let urlConfig = {
                            method: MetodoNovoOuAtualizaApi(
                                conteudoFormulario.dados[this.chavePrimaria]
                            ),
                            url: urlForm,
                            data: conteudoFormulario,
                        };

                        await ApiService(urlConfig)
                            .then((resposta) => {
                                this.$store.commit(
                                    'FORM_ACAO',
                                    'form-atualiza'
                                );

                                this.$emit(
                                    'mensagemAlerta',
                                    resposta.data.mensagem,
                                    'success'
                                );

                                this.form.tabsConteudo.dados[0][
                                    this.chavePrimaria
                                ] = resposta.data.conteudo.dados.id_registro;
                            })
                            .catch((erro) => {
                                throw new Error(erro.message);
                            });
                    });
            } catch (e) {
                this.$emit('mensagemAlerta', e.message, 'error');
            }
        },
        async apagaForm() {
            try {
                let dados = DatatableHelper.datatableGetLinhaSelecionadas(
                    this.moduloNome
                );

                let ids = dados
                    .map(function (dado) {
                        return dado.DT_RowId;
                    })
                    .join();

                if (!ids) {
                    this.$emit(
                        'mensagemAlerta',
                        'Selecione um registro antes de apagar.',
                        'error'
                    );

                    return false;
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Deleta', `Apagar registro(s) ?\n IDS - ${ids}`, {
                        cor: 'red',
                    })
                    .then(async (confirma) => {
                        if (!confirma) {
                            return false;
                        }

                        let urlConfig = {
                            method: 'DELETE',
                            url: `${API_URL.erp[this.moduloNome].apaga}/${ids}`,
                            params: { [this.chavePrimaria]: ids }
                        };

                        let dados = await ApiService(urlConfig)
                            .then(resposta => {
                                this.$emit('datatableRecarrega');

                                this.$emit(
                                    'mensagemAlerta',
                                    `Registro ${ids} Deletado com sucesso`,
                                    'success'
                                );
                            })
                            .catch(erro => {
                                throw new Error(erro.message);
                            });
                    });
            } catch (e) {
                this.$emit('mensagemAlerta', e.message, 'error');
            }
        },
        limpaFormulario() {
            this.$refs[this.formNome].reset();
        },
        atualizaFormComplemento: function (complemento) {
            this.form.complemento = complemento;
        },
    },
    data() {
        return {
            tabComponente: null,
            form: {
                tabsConteudo: {},
                complemento: {},
            },
        };
    },
};
</script>

<style scoped>
</style>
